/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Y no sólo en internet, pero hoy voy a tratar sobre el tema del comercio online y ubicuo. Como habréis visto en las últimas recopilaciones de enlaces he hablado sobre pago electrónico y monedas virtuales. Con el auge de los smartphones se puede empezar a imaginar la venta móvil, estés donde estés y donde se podría integrar el pago al momento de servicios de autónomos o ", React.createElement(_components.a, {
    href: "http://quartdesdecasa.blogspot.com/"
  }, "sistemas mixtos de compra"), ", en el futuro que nos viene las fronteras entre virtual y físico empiezan a estar borrosas. Es el mundo de la computación ubicua y vendrá del lado del móvil y las criptomonedas."), "\n", React.createElement(_components.p, null, "Podemos empezar a entrar en este mundo abriendo una tienda virtual, con buena integración con el móvil, pero tal y como está la competencia necesitamos algo más, necesitamos un relato donde mostrar nuestras ideas, mostrar quienes somos y por qué vendemos lo que vendemos, sólo de esta forma empezaremos a unirnos a la gente que piensa como nosotros, a tejer redes y formar entramados, empezaremos a crear comunidad. Por supuesto nuestra comunidad y toda esa red se convertirán en nuestros mejores clientes y nuestros mejores proveedores, como vendedores y fabricantes obtendremos la mayor satisfacción haciendo lo que queremos y como clientes ganaremos la posibilidad de tener el producto más personalizado posible, el que se amolda a todas nuestras necesidades. ¿Hay algo mejor? ¿Estoy sufriendo un ataque de super-optimismo? Es posible, pero vale la pena intentarlo."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
